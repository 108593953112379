<template>
  <van-field readonly disabled placeholder="--" autosize v-bind="$attrs" />
</template>

<script>
export default {
  name: "VanFieldEcho"
};
</script>

<style scoped></style>
